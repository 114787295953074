<template>
  <div class="table-responsive ">
    <div class="float-right">
      <a _target="blank" class="btn btn-primary" v-bind:href="downloadLink"  title="Download table">
        <span class="align-middle material-icons">save_alt</span>
        <span class="align-middle">&nbsp;Download&nbsp;CSV</span>
      </a>
    </div>

    <table class="table table-striped table-sm table-hover" style="width: 100%;">
      <thead>
        <tr>
          <th>
            <a
              class="sorting"
              v-on:click="sortBy('label')"
              title="Click to sort"
            >{{templates ? 'Template PDB chain ':'PDB Chain'}}</a>
          </th>
          <th>{{templates ?'Template PDB title':'Title'}}</th>
          <th>
            <a class="sorting" v-on:click="sortBy('start')" title="Click to sort">Start</a>
          </th>
          <th>
            <a class="sorting" v-on:click="sortBy('end')" title="Click to sort">End</a>
          </th>
          <th v-if="templates">
            <a class="sorting" v-on:click="sortBy('seq_id')" title="Click to sort">Sequence Identity</a>
          </th>
          <th>
            <a
              class="sorting"
              v-on:click="sortBy('method')"
              title="Click to sort"
            >{{templates ? 'Template method':'Method'}}</a>
          </th>
          <!-- <th v-if="! templates"><a class="sorting" v-on:click="sortBy('comment')" title="Click to sort">Comments</a></th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in sorted" v-bind:key="p.label">
          <td>
            <a
              target="_blank"
              v-bind:href="'//www.rcsb.org/structure/' + p.properties.pdbid"
            >{{p.label}}</a>
          </td>
          <td>{{p.properties.Title}}</td>
          <td>{{p.start}}</td>
          <td>{{p.end}}</td>
          <td v-if="templates">{{p.properties["Sequence identity"]}}</td>
          <td>
            <span v-bind:style="{ color: p.color}">{{p.properties.Method}}</span>
          </td>
          <!-- <td v-if="! templates">{{p.properties["comment"]}}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "StructuresTable",
  props: {
    structures: Array,
    templates: Boolean
  },
  data: function() {
    return {
      sortKey: "label",
      reverse: true
    };
  },
  computed: {
    downloadLink: function() {
      return this.templates
        ? this.$parent.modelsTable
        : this.$parent.structureTable;
    },
    sorted: function() {
      let k = this.sortKey;
      let o = this.reverse ? -1 : 1;
      let t = this.templates;

      let compare = function(a, b) {
        let res;
        if (t && k === "seq_id")
          res =
            parseFloat(a.properties["Sequence identity"]) >
            parseFloat(b.properties["Sequence identity"])
              ? 1
              : -1;
        else if (k === "start" || k === "end")
          res = parseInt(a[k]) > parseInt(b[k]) ? 1 : -1;
        else if (k === "method" || k === "comment")
          res = a.properties[k] > b.properties[k] ? 1 : -1;
        else res = a[k] > b[k] ? 1 : -1;
        return res * o;
      };

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.structures.sort(compare);
    }
  },
  methods: {
    sortBy: function(sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;

      this.sortKey = sortKey;
    }
  }
};
</script>

<style scoped>
a.sorting {
  font-weight: bolder;
  color: #007bff;
  cursor: pointer;
}
</style>
